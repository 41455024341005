<template>
<div>

  <v-container>
    <v-row>
      <v-col
        v-for="n in 9"
        :key="n"
        class="d-flex child-flex"
        cols="4"
      >
        <v-img
          :src="`https://picsum.photos/500/300?image=${n * 5 + 10}`"
          :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
          aspect-ratio="1"
          class="grey lighten-2"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>

    <h1 class="display-1 font-weight-500 mb-4">
      {{ $vuetify.lang.t('$vuetify.about.h1') }}
    </h1>
    <v-row dense>

      <v-col cols="12">
        <v-row dense>
          <v-col cols="12" md="6">
            <v-card
              dark
              color="black"
            >
              <v-card-text class="">
                <p v-html="$vuetify.lang.t('$vuetify.about.p1')">
                </p>

                <p v-text="$vuetify.lang.t('$vuetify.about.p2')"></p>
                <p v-text="$vuetify.lang.t('$vuetify.about.p3')"></p>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card
              dark
              color="black"
            >
              <v-card-text class="">
                <h2
                  v-text="$vuetify.lang.t('$vuetify.about.h2')"
                  class="mb-4"
                ></h2>
                <p v-text="$vuetify.lang.t('$vuetify.about.p4')"></p>
                <p v-text="$vuetify.lang.t('$vuetify.about.p5')"></p>
                <p v-text="$vuetify.lang.t('$vuetify.about.p6')"></p>
                <p v-text="$vuetify.lang.t('$vuetify.about.p7')"></p>
                <ul>
                  <li v-text="$vuetify.lang.t('$vuetify.about.li1')"></li>
                  <li v-text="$vuetify.lang.t('$vuetify.about.li2')"></li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <h2 class="display-1 font-weight-500 mb-4 mt-4">
          {{ $vuetify.lang.t('$vuetify.about.h3') }}
        </h2>
      </v-col>

      <v-col
        cols="12"
        md="6"
        v-for="(item, i) in team"
        :key="i"
      >
        <v-card
          dark
          color="black"
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title  v-text="item.name" class="headline"></v-card-title>

              <v-card-subtitle v-text="item.position"></v-card-subtitle>

              <v-card-actions class="pa-0">
                <v-card-text class="ma-0 pb-0" v-text="item.description"></v-card-text>
              </v-card-actions>

            </div>

            <v-avatar
              class="ma-3"
              size="200"
              tile
            >
              <v-img :src="item.photo"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col sm="12">
        <h2 class="display-1 font-weight-500 mb-4 mt-4">
          {{ $vuetify.lang.t('$vuetify.about.h4') }}
        </h2>
      </v-col>

      <v-col>
        <v-card light class="pa-4">
          <v-img src="../assets/turkish-airlines-logo.png"></v-img>
        </v-card>
      </v-col>
      <v-col>
        <v-card light class="pa-4">
          <v-img src="../assets/turkish-airlines-logo.png"></v-img>
        </v-card>
      </v-col>
      <v-col>
        <v-card light class="pa-4">
          <v-img src="../assets/turkish-airlines-logo.png"></v-img>
        </v-card>
      </v-col>
      <v-col>
        <v-card light class="pa-4">
          <v-img src="../assets/turkish-airlines-logo.png"></v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
import photo1 from '../assets/team/photo-1.jpg';
import photo2 from '../assets/team/photo-2.jpg';
import photo3 from '../assets/team/photo-3.jpg';
import photo4 from '../assets/team/photo-4.jpg';
import photo5 from '../assets/team/photo-5.jpg';
import photo6 from '../assets/team/photo-6.jpg';

export default {
  name: 'About',
  data: () => ({
    team: [
      {
        name: 'Alaa saad',
        position: 'Director Ejecutivo',
        description: 'Licenciatura de turismo, diploma en la historia griega romana y la antigua egipcia',
        photo: photo3,
      },
      {
        name: 'Mina magdi',
        position: 'Director Operativo',
        description: 'Licenciatura de turismo, diploma en coptologia (el idioma de los antiguos egipcios, y el actual de los cristianos egipcios',
        photo: photo5,
      },
      {
        name: 'Irene Matamoro Sanchez',
        position: 'Directora financiera',
        description: 'Diplomada en relaciones laborales, Graduada en Finanzas y Contabilidad',
        photo: photo1,
      },
      {
        name: 'Irene de Benito Ortiz',
        position: '',
        description: 'Técnico superior en administración y finanzas',
        photo: photo4,
      },
      {
        name: 'Alejandro Cruz Barbero',
        position: 'Operador',
        description: 'Grado de administración y dirección de empresas',
        photo: photo2,
      },
      {
        name: 'Jose Manuel Sales',
        position: 'Director de marketing (cmo)',
        description: 'Superior en Dirección de Marketing Global',
        photo: photo6,
      },
      {
        name: 'Marcelo  Alejandro Dallalba',
        position: 'Fotógrafo y diseños ',
        description: '',
        photo: photo4,
      },
      {
        name: 'Nevine saad ',
        position: 'Técnico de información',
        description: 'Licenciatura de informática y administrativa, encargada de redes sociales',
        photo: photo1,
      },
    ],
  }),
};
</script>

<style lang="scss">

</style>
